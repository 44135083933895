import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';

export default function WorkCard({ 
 title,
 company,
 year,
 status,
 thumbnail,
 description,
 caseStudyLink,
 tags = [],
 tools = [],
 onClick
}) {
 const handleCaseStudyClick = (e) => {
   e.stopPropagation();
   if (caseStudyLink && status !== "coming soon") {
     window.open(caseStudyLink, '_blank', 'noopener,noreferrer');
   }
 };

 const getLinkText = () => {
   if (status === "coming soon") {
     return "Case study coming soon";
   }
   return "See case study";
 };

 const isComingSoon = status === "coming soon";
 const textColorClass = isComingSoon ? "text-gray-400" : "";

 return (
   <motion.div
     className="group relative bg-white rounded-2xl overflow-hidden shadow-custom hover:shadow-custom-hover transition-all duration-300 border border-ng-100"
     initial={{ opacity: 0, y: 20 }}
     animate={{ opacity: 1, y: 0 }}
     transition={{ duration: 0.5 }}
   >
     <div className="relative aspect-[16/9] overflow-hidden bg-gray-100">
       <img
         src={thumbnail}
         alt={`${title} - ${company} project thumbnail`}
         className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105 rounded-2xl"
         onError={(e) => {
           e.target.src = '/placeholder.png';
           console.log(`Failed to load thumbnail for ${title}`);
         }}
       />
     </div>

     <div className="p-6 space-y-3">
       <div className="flex items-center justify-between">
         <span className={`text-sm font-medium ${textColorClass || "text-gray-600"}`}>{company}</span>
         <span className={`text-sm ${textColorClass || "text-gray-500"}`}>{year}</span>
       </div>

       <h3 className={`text-lg font-semibold ${textColorClass || "text-gray-900 group-hover:text-gray-700"} transition-colors`}>
         {title}
       </h3>
       
       <p className={`line-clamp-2 text-md ${textColorClass || "text-gray-600"}`}>
         {description}
       </p>

       <div className="flex items-center justify-between pt-4">
         <button
           onClick={handleCaseStudyClick}
           className={`flex items-center transition-colors ${
             isComingSoon 
               ? "text-gray-400 cursor-not-allowed" 
               : "text-gray-900 font-medium hover:text-gray-600"
           }`}
         >
           <span className="relative z-10 pr-1">{getLinkText()}</span>
           {!isComingSoon && (
             <svg
               className="w-4 h-4 transition-transform group-hover:translate-x-0.5"
               fill="none"
               stroke="currentColor"
               viewBox="0 0 24 24"
             >
               <path
                 strokeLinecap="round"
                 strokeLinejoin="round"
                 strokeWidth={2}
                 d="M14 5l7 7m0 0l-7 7m7-7H3"
               />
             </svg>
           )}
         </button>
       </div>
     </div>

     <div className="absolute inset-0 bg-black/[0.01] opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none" />
   </motion.div>
 );
}

WorkCard.defaultProps = {
 tags: [],
 tools: [],
 onClick: () => {},
};