import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';

const AvatarWithStory = ({ avatarSrc, updateVideo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const lastUpdated = new Date('2024-11-24T10:00:00');

  useEffect(() => {
    const hasClicked = localStorage.getItem('hasClickedUpdate');
    if (!hasClicked) setShowTooltip(true);
  }, []);

  const handleClick = () => {
    setIsOpen(true);
    setShowTooltip(false);
    localStorage.setItem('hasClickedUpdate', 'true');
  };

  const getTimeElapsed = () => {
    const now = new Date();
    const diff = now - lastUpdated;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(hours / 24);
    
    if (days === 0) return `${hours} hours ago`;
    if (days === 1) return `1 day ago`;
    return `${days} days ago`;
  };

  return (
    <div className="flex flex-col items-center gap-4 mb-2">
      <AnimatePresence>
        {showTooltip && (
          <motion.div 
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: [0, -4, 0] }}
            exit={{ opacity: 0, y: -5 }}
            transition={{ 
              y: { repeat: Infinity, duration: 2, ease: "easeInOut" },
              opacity: { duration: 0.2 }
            }}
          >
            <div className="bg-gray-900 text-white px-2 py-1 rounded text-sm whitespace-nowrap">
              New update!
              <div className="absolute w-0 h-0 left-1/2 -translate-x-1/2 bottom-[-6px] border-l-[6px] border-l-transparent border-t-[6px] border-t-gray-900 border-r-[6px] border-r-transparent" />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="relative w-24 h-24 cursor-pointer" onClick={handleClick}>
        <motion.div className="absolute inset-0" animate={{ rotate: 360 }} transition={{ duration: 2, repeat: Infinity, ease: "linear" }}>
          <svg viewBox="0 0 100 100" className="w-full h-full">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#ff3b3b" />
                <stop offset="50%" stopColor="#ff3bff" />
                <stop offset="100%" stopColor="#3b3bff" />
              </linearGradient>
            </defs>
            <motion.circle cx="50" cy="50" r="48" stroke="url(#gradient)" strokeWidth="4" fill="none" />
          </svg>
        </motion.div>
        <div className="absolute inset-2 rounded-full overflow-hidden">
          <img src={avatarSrc} alt="Avatar" className="w-full h-full object-cover" />
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div 
            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={(e) => e.target === e.currentTarget && setIsOpen(false)}
          >
            <motion.div 
              className="relative w-full max-w-3xl mx-4"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="bg-white rounded-t-lg p-4 flex items-center justify-between">
                <p className="text-gray-600">Last updated: {getTimeElapsed()}</p>
                <button onClick={() => setIsOpen(false)} className="text-gray-500 hover:text-gray-700">
                  <X size={24} />
                </button>
              </div>
              <video className="w-full rounded-b-lg" controls autoPlay src={updateVideo} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AvatarWithStory;