import { motion } from 'framer-motion';
import avatar from '../../assets/images/avatar.jpeg';
import KeyboardButton from '../common/KeyboardButton';
import AvatarWithStory from '../common/AvatarWithStory';
import Updatevideo from '../../assets/videos/update.mp4';

export default function Hero() {
    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0, transition: { duration: 0.5 } }
    };

    return (
        <motion.section
            className="relative w-full mx-auto pt-24 pb-16 overflow-hidden"
            variants={container}
            initial="hidden"
            animate="show"
        >
            {/* Edge Background Lines */}
            <div className="absolute inset-0">
                {/* Left edge line */}
                <div className="absolute left-2 top-0 w-px h-full bg-gradient-to-b from-transparent via-gray-200/50 to-transparent" />
                {/* Right edge line */}
                <div className="absolute right-2 top-0 w-px h-full bg-gradient-to-b from-transparent via-gray-200/50 to-transparent" />
            </div>

            {/* Center Background Lines */}
            <div className="absolute inset-0 flex justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="w-px h-full bg-gradient-to-b from-transparent via-gray-200/50 to-transparent" />
                <div className="w-px h-full bg-gradient-to-b from-transparent via-gray-200/50 to-transparent" />
                <div className="w-px h-full bg-gradient-to-b from-transparent via-gray-200/50 to-transparent" />
                <div className="w-px h-full bg-gradient-to-b from-transparent via-gray-200/50 to-transparent" />
            </div>

            {/* Content */}
            <div className="relative flex flex-col items-center text-center px-4 sm:px-6 lg:px-8">
                <motion.div className="w-20 h-20 sm:w-24 sm:h-24 mb-12" variants={item}>
                    {/* <img
                        src={avatar}
                        alt="Shubham Bhatt"
                        className="w-full h-full object-cover shadow-xl rounded-md"
                    /> */}
                   <AvatarWithStory avatarSrc={avatar} updateVideo={Updatevideo} />
                </motion.div>

                <motion.h1
                    className="text-3xl sm:text-4xl md:text-4xl font-semibold text-gray-900 mb-3 sm:mb-4 !leading-[1.3] tracking-normal max-w-[720px]"
                    variants={item}
                >
                    Hi, I am a hyper generalist, with a specialisation in digital product design
                </motion.h1>

                <motion.p
                    className="text-base sm:text-lg font-normal text-gray-800 mb-6 sm:mb-8 max-w-2xl leading-[1.6] tracking-normal"
                    variants={item}
                >
                    Lately, I have been working at the intersection of design, code
                    and AI. I have previously worked with Quizizz and udaan.
                </motion.p>

                <motion.div
                    className="flex flex-col sm:flex-row gap-3 w-full sm:w-auto"
                    variants={item}
                >
                    <a
                        href="https://www.formlite.co/public-form/1FAIpQLSdtOSohfVU0yzQ4yCyvp98qAJJ2AlGrzZrnf-ZK0iFjUMozYA?template=Typeform"
                        target='_blank'
                        className="px-5 py-2.5 bg-gray-800 text-white text-sm sm:text-md font-medium rounded-2xl hover:bg-gray-900 transition-colors"
                    >
                        Let's talk
                    </a>
                    <a
                        href="https://drive.google.com/file/d/19J0yD8AfAX8Y9QZM-dTbnMZO-XOAhWfw/view?usp=sharing"
                        target='_blank'
                        className="px-5 py-2.5 bg-white text-gray-900 text-sm sm:text-md font-medium rounded-2xl border border-gray-300 hover:border-gray-400 transition-colors"
                    >
                        Résumé
                    </a>
                </motion.div>
            </div>
        </motion.section>
    );
}