// src/data/projects.js
import formliteMockup from '../assets/images/formlite-mockup.png';
import createfulMockup from '../assets/images/createful-mockup.png';
import imagifMockup from '../assets/images/imagif-mockup.png';
import ebMockup from '../assets/images/eb-mockup.png';
import adeMockup from '../assets/images/ade-mockup.png';
import dbMockup from '../assets/images/DB-mockup.png';

export const projects = [
  {
    title: "Formlite: Google form makeover",
    description: "You don't need typeform. Just a handy Google Form.",
    longDescription: "Transform your Google Forms into engaging, conversational experiences without the complexity and cost of Typeform. Built with React and Claude",
    image: formliteMockup,
    siteUrl: "https://formlite.co",
    tags: ["React", "Google Forms API", "SaaS"],
    status: "LIVE",
    metrics: ["98 Users", "2 paying users"],
    launchDate: "2024"
  },
  {
    title: "Createful: LI Ops Tool",
    description: "LinkedIn Ops tool to manage your LinkedIn content creation journey",
    longDescription: "It helps in scheduling, content creation, content ideas and everything else you need to manage LinkedIn content creation",
    image: createfulMockup,
    siteUrl: "https://createful.co",
    tags: ["React", "LinkedIn"],
    status: "BETA",
    metrics: ["6 Paying Customers", "100 registered users"],
    launchDate: "2024"
  },
  {
    title: "Imagif Figma Plugin: Gif + Images",
    description: "Populate images and Gifs right inside your Figma file",
    longDescription: "Imagif is your one-stop solution for placing images & GIFs right into your design file. Insert stock images right into your Figma files. Also, GIFs are not very common to use in Figma but one use case would be the presentations.",
    image: imagifMockup,
    siteUrl: "https://www.figma.com/community/plugin/853653804321575174/imagif",
    tags: ["Design Tool", "Figma"],
    status: "LIVE",
    metrics: ["22k+ users"],
    launchDate: "2022"
  },
  {
    title: "Doorbell Hub",
    description: "Amazon affiliate website for Doorbell Reviews",
    longDescription: "Built this amazon affiliate website on wordpress in 2018 and sold it for 4 figure dollars.",
    image: dbMockup,
    siteUrl: "https://web.archive.org/web/20211209041306/https://doorbellhub.com/",
    tags: ["Blogging", "Figma"],
    status: "SOLD",
    metrics: ["4 Figure dollars"],
    launchDate: "2018"
  },
  {
    title: "All Design Events",
    description: "Curated design events from all over the world",
    longDescription: "All design events was a curated directory of weekly design events built on webflow.",
    image: adeMockup,
    siteUrl: "https://medium.com/@shubham-bhatt/solving-for-the-discovery-of-design-events-from-idea-to-execution-a88bf9ed3421",
    tags: ["Design Tool", "Figma"],
    status: "DISCONTIUED",
    metrics: ["392 events curated", "10K+ pageviews"],
    launchDate: "2022"
  },
  {
    title: "Easy Bookmarks",
    description: "This bookmark extension displays your bookmarks in a new tab",
    longDescription: "A bookmark extension that displays your bookmarks in a new tab is designed to enhance how you access and organize your saved web pages.",
    image: ebMockup,
    siteUrl: "https://chromewebstore.google.com/detail/easy-bookmarks/mnebclaodhnpfmcckbfdccpcpeonjjbp?hl=en",
    tags: ["Design Tool", "Figma"],
    status: "LIVE",
    metrics: ["12+ users"],
    launchDate: "2023"
  }
 

];