import { motion } from 'framer-motion';
import { useState, useRef } from 'react';

export default function ProjectCard({ 
  title, 
  description, 
  image, 
  siteUrl,
  status,
  tags = [],
  metrics = [],
  launchDate,
  onClick
}) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const cardRef = useRef(null);

  const statusColors = {
    'Live': 'bg-green-100 text-green-800',
    'Beta': 'bg-yellow-100 text-yellow-800',
    'Coming Soon': 'bg-blue-100 text-blue-800',
    'Archived': 'bg-gray-100 text-gray-800'
  };

  const handleMouseMove = (e) => {
    if (!cardRef.current) return;
    const rect = cardRef.current.getBoundingClientRect();
    setMousePosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  return (
    <motion.div
      ref={cardRef}
      onMouseMove={handleMouseMove}
      className="group relative bg-white rounded-2xl overflow-hidden shadow-custom hover:shadow-custom-hover transition-all duration-300 border border-ng-100"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Following Pointer Gradient */}
      <motion.div
        className="pointer-events-none absolute inset-0 z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        style={{
          background: `radial-gradient(
            400px circle at ${mousePosition.x}px ${mousePosition.y}px,
            rgba(156,139,242,0.1),
            rgba(156,139,242,0.05) 40%,
            transparent 60%
          )`
        }}
      />

      {/* Project Image */}
      <div className="relative aspect-[16/9] overflow-hidden bg-gray-100 rounded-2xl">
        <img
          src={image}
          alt={title}
          className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
          onError={(e) => {
            e.target.src = '/placeholder.png';
            console.log(`Failed to load image for ${title}`);
          }}
        />
      </div>

      {/* Content */}
      <div className="relative z-20 p-6 space-y-4">
        {/* Status, Launch Date, and Tags */}
        <div className="flex flex-wrap items-center gap-2">
          {/* Status Badge */}
          <span className={`px-2.5 py-1 rounded-full text-xs font-medium ${statusColors[status] || 'bg-gray-100 text-gray-800'}`}>
            {status}
          </span>
          
          {/* Launch Date */}
          {/* {launchDate && (
            <span className="text-xs text-gray-500">
              {launchDate}
            </span>
          )} */}
          
          {/* Tags */}
          {/* <div className="flex flex-wrap gap-2">
            {tags.map((tag, index) => (
              <span 
                key={index}
                className="px-2.5 py-1 bg-gray-100 text-gray-600 rounded-full text-xs"
              >
                {tag}
              </span>
            ))}
          </div> */}
        </div>

        {/* Title and Description */}
        <div className="space-y-2">
          <h3 className="text-xl font-semibold text-gray-900 group-hover:text-gray-700 transition-colors">
            {title}
          </h3>
          <p className="text-gray-600 line-clamp-2">
            {description}
          </p>
        </div>

        {/* Metrics */}
        {metrics && metrics.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {metrics.map((metric, index) => (
              <span 
                key={index}
                className="text-sm text-gray-500"
              >
                {metric}{index < metrics.length - 1 && " • "}
              </span>
            ))}
          </div>
        )}
        
        {/* Action Links */}
        <div className="flex items-center space-x-4 pt-2">
          <a
            href={siteUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="relative text-gray-900 font-medium hover:text-gray-900 flex items-center transition-colors"
          >
            <span className="relative z-10 pr-1">Visit site </span>
            <svg
            className="w-4 h-4 transition-transform group-hover:translate-x-0.5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </svg>
          </a>
        </div>
      </div>
    </motion.div>
  );
}