// src/data/work.js
import udaanMockup from '../assets/images/udaan-mockup.png';
import quizizzMockup from '../assets/images/quizizz-mockup.png';
import QBackMockup from '../assets/images/QBack.png';


export const workData = [
  {
    title: "0 to 1: Easing the workflows and saving time for PMMs",
    company: "QBack",
    year: "2024",
    status: "coming soon",
    thumbnail: QBackMockup,
    caseStudyLink: "https://www.figma.com/proto/CZO4K9Om6jPcTfNNhdLoc0/shubhambhatt.com?page-id=83%3A2407&node-id=83-6322&node-type=frame&viewport=1136%2C27%2C0.03&t=AGDsThlrLUIFE8kN-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=83%3A6322",
    description: "Building a SaaS tool for Product marketing managers which saves them time & eases their workflows.",
    fullDescription: `
      A complete overhaul of Shopify's merchant platform focusing on improved usability, 
      accessibility, and performance. The project involved extensive user research, 
      iterative design processes, and close collaboration with engineering teams.
      
      Key Achievements:
      • Increased merchant satisfaction by 40%
      • Reduced development time by 35%
      • Implemented design system used by 50+ teams
      • Improved platform accessibility to WCAG 2.1 AA standards
    `,
    tags: ["Design Systems", "UX Design", "Platform Design", "Enterprise"],
    deliverables: ["Design System", "Platform Redesign", "Documentation"],
    tools: ["Figma", "React", "Storybook"]
  },
  {
    title: "Designing an interactive question type for educators & students.",
    company: "Quizizz",
    year: "2023",
    status: "live",
    thumbnail: quizizzMockup,
    caseStudyLink: "https://www.figma.com/proto/CZO4K9Om6jPcTfNNhdLoc0/shubhambhatt.com?page-id=83%3A2407&node-id=306-5911&node-type=frame&viewport=1195%2C134%2C0.03&t=7RnPlqH3YcHcqayj-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=306%3A5911",
    description: "Building a new question type for educators and students in record time.",
    fullDescription: `
      A complete overhaul of Shopify's merchant platform focusing on improved usability, 
      accessibility, and performance. The project involved extensive user research, 
      iterative design processes, and close collaboration with engineering teams.
      
      Key Achievements:
      • Increased merchant satisfaction by 40%
      • Reduced development time by 35%
      • Implemented design system used by 50+ teams
      • Improved platform accessibility to WCAG 2.1 AA standards
    `,
    tags: ["Design Systems", "UX Design", "Platform Design", "Enterprise"],
    deliverables: ["Design System", "Platform Redesign", "Documentation"],
    tools: ["Figma", "React", "Storybook"]
  },
  {
    title: "How we achieved ~1.8x GMV growth through incentivisation?",
    company: "udaan",
    year: "2022",
    status: "live",
    thumbnail: udaanMockup,
    caseStudyLink: "https://www.figma.com/proto/CZO4K9Om6jPcTfNNhdLoc0/shubhambhatt.com?page-id=83%3A2407&node-id=83-6322&node-type=frame&viewport=1136%2C27%2C0.03&t=AGDsThlrLUIFE8kN-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=83%3A6322",
    description: "Worked on an incentivisation feature of merchants which led to a massive growth in GMV.",
    fullDescription: `
      A complete overhaul of Shopify's merchant platform focusing on improved usability, 
      accessibility, and performance. The project involved extensive user research, 
      iterative design processes, and close collaboration with engineering teams.
      
      Key Achievements:
      • Increased merchant satisfaction by 40%
      • Reduced development time by 35%
      • Implemented design system used by 50+ teams
      • Improved platform accessibility to WCAG 2.1 AA standards
    `,
    tags: ["Design Systems", "UX Design", "Platform Design", "Enterprise"],
    deliverables: ["Design System", "Platform Redesign", "Documentation"],
    tools: ["Figma", "React", "Storybook"]
  }
];